<!--
File: TreatmentsDropdown.vue
Description: show the dropdown combo with the treatments list .
-->
<template>
  <md-field>
    <label for="trList">{{ label }}</label>
    <md-select v-model='tr_id' name="trList" id="trList" :disabled="disabled" :required="is_required"
      @md-selected='onChange'>
      <md-option v-for='(tr, ind) in treatments' :key='ind' :value='tr.treatment_type_id'>
        {{ tr.key }} - {{ tr.treatment_type_description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'treatments-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        tr_id: null,
        treatmentsListLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('LOAD_TREATMENT_TYPES_LIST', this.is_required).then(() => {
        this.treatmentsListLoaded = true
        if (this.initial_value && !this.tr_id) this.tr_id = this.initial_value
      })
    },

    methods: {
      onChange() {
        const tr = !this.tr_id ? '' : this.treatments.find((el) => el.treatment_type_id == this.tr_id)
        const desc = !tr ? '' : tr.treatment_type_description
        this.$emit('input', this.tr_id, desc)
      },
    },

    computed: {
      treatments() {
        return this.$store.state.Treatments.treatmentTypesList;
      }
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.treatmentsListLoaded) {
          this.tr_id = newValue
        }
      },
    }
  }
</script>